<template>
  <BModal
    ref="modal-reset"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <BRow class="p-1 m-1">
      <BCol cols="12" class="d-flex justify-content-center mb-2">
        <BImg
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        />
      </BCol>
      <BCol cols="12" class="mb-2 text-center">
        <span class="text-black">
          Yakin mau menghapus semua data di Speredsheet kamu?
        </span>
      </BCol>
      <BCol cols="12" class="text-center">
        <BButton variant="outline-primary" class="mr-2 action-btn" @click="hide">
          <strong>
            Batal
          </strong>
        </BButton>
        <BButton variant="primary" class="action-btn" @click="reset">
          <strong>
            Reset
          </strong>
        </BButton>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
export default {
  methods: {
    show() {
      this.$refs['modal-reset'].show()
    },
    hide() {
      this.$refs['modal-reset'].hide()
    },
    reset() {
      this.$emit('resetSheetsData')
    },
  },
}
</script>

<style scoped>
  .action-btn {
    width: 110px;
    height: 45px;
    border-radius: 12px;
    letter-spacing: 1px;
  }
</style>
